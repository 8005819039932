import React from "react";
import SocialIcons from "./social-icons";

const Footer = () => {
  return (
    <div className="bg-dark p-5">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3  g-3 mb-5 container-fluid">
        <div className="col bg-inf">
          <div className="text-white ">HANCOCK AUTOMOTIVES</div>
          <div className="d-flex">
            <SocialIcons />
          </div>
        </div>
        <div className="col text-white">
          <div className="">Address</div>
          <div className="fw-lighter">
            4/12, Statham Street
            <br />
            Bennetts Green, 2290.
            <br />
            Ph: 0249488851
            <br />
            Email: mhautomotive2@gmail.com
          </div>
        </div>
        <div className="col text-white">
          <div className="">Opening Hours</div>
          <div className="fw-lighter">
            Mon - Fri: 8am - 5pm
            <br />
            Sat: 8am - 1pm
            <br />
            Sun: CLOSED
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
