import React, { useState } from "react";
import Pic1 from "./../images/short1.jpeg";
import Pic2 from "./../images/short2.jpeg";
import Pic3 from "./../images/short3.jpeg";
import Pic4 from "./../images/short4.jpeg";
import Pic5 from "./../images/short5.jpeg";

const Section3 = () => {
  const [hover, setHover] = useState(0);

  return (
    <div className="mt-5 container  ">
      <div className="row row-cols-1 row-cols-sm-2  g-3 mb-5">
        <div className="col px-2 ">
          <a className="picturebox" href="/#">
            <img src={Pic1} alt="Tyres" className="img-fluid boxImage " />
            <div className="middle">
              <div
                className=" text-white fw-bolder  h2"
                style={{
                  // fontSize: "3vw",
                  textShadow: "0 0 3px red, 0 0 5px red",
                }}
              >
                TYRES
              </div>
            </div>
            <div className="position-absolute bottom-0 bg-danger w-25 text-center text-white">
              Learn More
            </div>
          </a>
        </div>
        <div className="col px-2 ">
          <a className="picturebox" href="/#">
            <img src={Pic2} alt="Wheels" className="img-fluid boxImage " />
            <div className="middle">
              <div
                className=" text-white fw-bolder  h2"
                style={{
                  // fontSize: "3vw",
                  textShadow: "0 0 3px red, 0 0 5px red",
                }}
              >
                WHEELS
              </div>
            </div>
            <div className="position-absolute bottom-0 bg-danger w-25 text-center text-white">
              Learn More
            </div>
          </a>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3  g-3">
        <div className="col px-2">
          <a className="picturebox" href="/#">
            <img src={Pic3} alt="Wheels" className="img-fluid boxImage " />
            <div className="middle">
              <div
                className=" text-white fw-bolder  h2"
                style={{
                  // fontSize: "3vw",
                  textShadow: "0 0 3px red, 0 0 5px red",
                }}
              >
                SERVICE
              </div>
            </div>
            <div className="position-absolute bottom-0 bg-danger w-25 text-center text-white">
              Learn More
            </div>
          </a>
        </div>
        <div className="col px-2">
          <a className="picturebox" href="/#">
            <img src={Pic4} alt="Wheels" className="img-fluid boxImage " />
            <div className="middle">
              <div
                className=" text-white fw-bolder  h2"
                style={{
                  // fontSize: "3vw",
                  textShadow: "0 0 3px red, 0 0 5px red",
                }}
              >
                SPECIAL
              </div>
            </div>
            <div className="position-absolute bottom-0 bg-danger w-25 text-center text-white">
              Learn More
            </div>
          </a>
        </div>
        <div className="col px-2">
          <a className="picturebox" href="/#">
            <img src={Pic5} alt="Wheels" className="img-fluid boxImage " />
            <div className="middle">
              <div
                className=" text-white fw-bolder  h2"
                style={{
                  // fontSize: "3vw",
                  textShadow: "0 0 3px red, 0 0 5px red",
                }}
              >
                GALLERY
              </div>
            </div>
            <div className="position-absolute bottom-0 bg-danger w-25 text-center text-white">
              Learn More
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section3;
