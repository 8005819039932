import React from "react";
import { Parallax } from "react-parallax";
import Pic from "./../images/section2.jpg";
import "./styles.css";
const Section2 = () => {
  return (
    <div>
      <Parallax
        bgImage={Pic}
        blur={3}
        strength={200}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: "rgba(255, 255, 255, .3)",
              // left: "50%",
              // top: "50%",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
        )}
      >
        <div className="parallax position-relative">
          {/* PROVIDING DISCOUNT TYRES TO NEWCASTLE FOR OVER 4 YEARS! */}
          <div
            className="position-absolute  ms-2 ms-md-5 "
            style={{ top: "15%", width: "80%" }}
          >
            <p
              className="fw-bold text-white h3"
              style={{
                fontSize: "3.5vw",
                textShadow: "0 0 3px black, 0 0 1px black",
              }}
            >
              {" "}
              PROVIDING DISCOUNT TYRES TO NEWCASTLE FOR OVER 4 YEARS!
            </p>
            <p
              style={{
                fontSize: "3vw",
                // textShadow: "0 0 3px red, 0 0 1px red",
              }}
              className="fw-light "
            >
              Broadmeadow Tyres & Service not only does tyres but can take care
              of your car from Logbook Servicing, Mechanical Repairs, Suspension
              Repairs, Brakes, Air Con Repairs, Wheel Alignment and Windscreen
              Repair/Replacement.
            </p>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default Section2;
