import React from "react";
import "./style.css";
const NavBar = () => {
  const NavItems = [
    { NavCol: "HOME", subItems: [], active: true },
    {
      NavCol: "TYRES",
      subItems: [
        { key: "SEARCH BY VEHICLE" },
        { key: "SEARCH BY SIZE" },
        { key: "SEARCH BY BRAND" },
      ],
      active: false,
    },
    {
      NavCol: "WHEELS",
      subItems: [{ key: "SEARCH BY VEHICLE" }, { key: "SEARCH BY BRAND" }],
      active: false,
    },
    {
      NavCol: "CAR SERVICE",
      subItems: [],
      active: false,
    },
    {
      NavCol: "SERVICES",
      subItems: [
        { key: "AIR CONDITIONING" },
        { key: "BRAKES" },
        { key: "CAR REPAIRS" },
        { key: "PINK SLIPS" },
        { key: "SUSPENSION" },
        { key: "TOYOTA HYBRID CAR REPAIRS" },
        { key: "WHEEL ALIGNMENT" },
        { key: "WHEEL BALANCING" },
        { key: "BLUE SLIP" },
      ],
      active: false,
    },
    {
      NavCol: "INTEREST FREE",
      subItems: [
        { key: "ZIP" },
        { key: "AFTERPAY" },
        { key: "HUMM" },
        { key: "SKYE" },
      ],
      active: false,
    },
    {
      NavCol: "SPECIAL",
      subItems: [],
      active: false,
    },
    {
      NavCol: "GALLERY",
      subItems: [],
      active: false,
    },
    {
      NavCol: "REVIEWS",
      subItems: [],
      active: false,
    },
    {
      NavCol: "CONTACT US",
      subItems: [],
      active: false,
    },
    {
      NavCol: "CAREER",
      subItems: [],
      active: false,
    },
  ];

  const FillNav = () => {
    return NavItems.map((item, index) => {
      if (+item.subItems.length === 0) {
        return (
          <li
            className={["nav-item ", item.active ? "active" : " "].join(" ")}
            key={index}
          >
            <a
              className="nav-link "
              aria-current="page"
              href="#"
              onClick={() => {
                console.log(Object.keys(item.subItems).length);
              }}
            >
              {item.NavCol}
            </a>
          </li>
        );
      }

      return (
        <li className="nav-item dropdown" key={index}>
          <a
            className="nav-link dropdown-togge"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {item.NavCol}
          </a>
          <ul className="dropdown-menu">
            {item.subItems.map((subItem, index) => {
              return (
                <li key={index}>
                  <a className="dropdown-item" href="#">
                    {subItem.key}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      );
    });
  };

  return (
    <nav
      className="navbar navbar-expand-md bg-dark sticky-top py-0"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <div
          className="navbar-toggler ms-auto border-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          onClick={() => {
            console.log("clicked");
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </div>
        <div
          className="offcanvas offcanvas-end w-75"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              HANCOCK AUTOMOTIVES
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body " id="offcanvas">
            <ul className="navbar-nav justify-content-center flex-grow-1 pe-3 ">
              {FillNav()}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
