import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Banner1 from "./../images/banner-ad1.png";
import Banner2 from "./../images/banner-ad2.jpeg";
import Banner3 from "./../images/banner-ad3.jpeg";
import Banner4 from "./../images/banner-ad4.jpeg";
const Corousal = () => {
  return (
    <div>
      {/* <img
        src={Banner1}
        width="100%"
        height="auto"
        className="my-auto"
        alt=""
      /> */}

      <Carousel
        autoPlay
        infiniteLoop
        interval={2000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
      >
        <div>
          <img src={Banner1} />
          {/* <p className="legend">Legend 1</p> */}
        </div>
        <div>
          <img src={Banner2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img src={Banner3} />
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={Banner4} />
          {/* <p className="legend">Legend 3</p> */}
        </div>
      </Carousel>
    </div>
  );
};

export default Corousal;
