import logo from "./logo.svg";
import "./App.css";
import Header from "./static/header";
import NavBar from "./static/navbar";
import Corousal from "./banner/corousal";
import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import Section4 from "./sections/section4";
import Footer from "./static/footer";
function App() {
  return (
    <div>
      <Header />
      <NavBar />
      <Corousal />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
}

export default App;
