import React from "react";

const SocialIcons = () => {
  return (
    <div
      className="d-flex justify-content-between fs-4"
      // style={{ fontSize: "18px" }}
    >
      <a href="/#" className="p-sm-1">
        <i className="bi bi-facebook "></i>
      </a>
      <a href="/#" className="p-sm-1">
        <i className="bi bi-whatsapp text-success"></i>
      </a>
      <a href="/#" className="p-sm-1">
        <i className="bi bi-twitter-x text-gray"></i>
      </a>
      <a href="/#" className="p-sm-1">
        <i className="bi bi-instagram text-danger"></i>
      </a>
    </div>
  );
};

export default SocialIcons;
