import React from "react";

const Section1 = () => {
  return (
    <div className="text-center container my-5 px-5">
      <div className="h3 fw-bold"> WELCOME TO HANCOCK AUTOMOTIVES </div>
      <div className="ruler w-25 mx-auto" />
      <div>
        <p />
        The friendly and experienced team at Broadmeadow Tyres and Service have
        been servicing the Newcastle area and surrounds for 3 years now. With a
        reputation for going above and beyond for our customers, we are proud to
        have been awarded the Kumho Platinum Dealer status.
        <p />
        Our store is located at 4 Newton Street. We use respected tyre brands
        which cater to all budgets, to ensure that your vehicle’s driving needs
        are met. Broadmeadow Tyres and Service is proud to partner with Kumho
        Platinum, and our status ensures we can bring you the best prices on a
        massive range of Kumho Tyres.
        <p />
        We’re your perfect choice for vehicle repairs and servicing. Our
        experienced staff can offer you professional advice on a range of
        services including wheel alignments, logbook servicing, suspension
        repairs and mechanical repairs.
        <p />
        When your vehicle is due for a service, or in need of repair, contact
        Broadmeadow Tyres and Service on 02 4048 0108 or come in and see Kam and
        the rest of the team at 4 Newton Street to discuss your next service or
        to book in your vehicle.
      </div>
    </div>
  );
};

export default Section1;
