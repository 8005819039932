import React from "react";
import Logo from "./../images/hancocklogo.png";
import SocialIcons from "./social-icons";
import LogoName from "./../images/logo-name.png";
const Header = () => {
  return (
    <div className="d-flex justify-content-between  p-2 px-md-5 bg-body-tertiary">
      <img src={Logo} width="10%" height="auto" className="my-auto" alt="" />

      <img
        src={LogoName}
        width="40%"
        height="auto"
        className="my-auto"
        alt=""
      />
      <div className="my-auto">
        <SocialIcons />
      </div>
    </div>
  );
};

export default Header;
