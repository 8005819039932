import React from "react";
import Pic from "./../images/footerbanner.png";
const Section4 = () => {
  return (
    <div className="mt-5">
      <img src={Pic} className="img-fluid" />
    </div>
  );
};

export default Section4;
